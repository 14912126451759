import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { strings } from '../../resources/strings';

export class PrivacyPolicy extends Component {
    render() {
        return (
            <Container fluid className="mb-5 px-md-6">
                <Row className="mt-5">
                    <h5>{strings.privacyPolicy}</h5>
                </Row>
                <Row className="mt-5">
                    <p>Leia por favor esta Política de Privacidade com atenção, pois no acesso a este site,
                        a disponibilização dos seus dados pessoais implica o conhecimento e aceitação das
                        condições aqui constantes.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Quem é o responsável pela recolha e tratamento dos dados?</b></p>
                    <p>A Transportes do Alto Alentejo S.A é a entidade responsável pela recolha e tratamento
                        dos dados pessoais, podendo, no âmbito da sua actividade, recorrer a entidades por
                        si subcontratadas para a prossecução das finalidades aqui indicadas.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Quem é o Encarregado de Proteção de dados?</b></p>
                    <p>O encarregado de Protecção de dados é a “Protect Data Consulting”, com morada
                        na Rua de Campolide, n.º 164 D, 1070-037 Lisboa, e os seguintes contacto
                        telefónico: 216083898. </p>
                </Row>
                <Row className="mt-5">
                    <p><b>O que são dados pessoais?</b></p>
                    <p>Dados pessoais são qualquer informação, de qualquer natureza e
                        independentemente do respetivo suporte, incluindo som e imagem, relativa a uma
                        pessoa singular identificada ou identificável. É considerada identificável a pessoa
                        que possa ser identificada direta ou indiretamente, designadamente por referência a
                        um número de identificação ou a um ou mais elementos específicos da sua
                        identidade física, fisiológica, psíquica, económica, cultural ou social.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Que dados pessoais são recolhidos e tratados?</b></p>
                    <p>A Transportes do Alto Alentejo S.A procede à recolha e ao tratamento, entre outros, dos
                        seguintes dados pessoais: nome, número de telefone e endereço de correio
                        eletrónico.</p>
                    <p>A Transportes do Alto Alentejo S.A assume que os dados foram fornecidos pelo titular dos
                        mesmos ou que este deu autorização para o efeito e presume que os mesmos são
                        verdadeiros e se encontram atualizados.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>De que forma são recolhidos os seus dados?</b></p>
                    <p>Os dados pessoais podem ser recolhidos através dos seguintes meios:</p>
                    <p>
                        <ol type="a">
                            <li>Correio electrónico;</li>
                            <li>Website;</li>
                            <li>Chamadas telefónicas;</li>
                            <li>Presencialmente;</li>
                            <li>Através de aplicações (“apps”) instaladas em dispositivos móveis.</li>
                        </ol>
                    </p>
                    <p>Os dados recolhidos são processados e armazenados informaticamente e no estrito
                        cumprimento da legislação de proteção de dados pessoais sendo armazenados em
                        base de dados específicas, criadas para o efeito, pela Rodoviaria do Alentejo, SA ou
                        pelas entidades por esta subcontratadas.</p>
                    <p>Alguns dados pessoais recolhidos no website são de preenchimento obrigatório e,
                        em caso de falta ou insuficiência desses dados, a Transportes do Alto Alentejo S.A
                        poderá não conseguir prestar-lhe - os serviços ou as informações por si solicitadas. Em cada
                        caso concreto, a Transportes do Alto Alentejo S.A informá-lo-á da natureza
                        obrigatória do fornecimento dos dados pessoais em causa.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Qual a finalidade dos dados recolhidos?</b></p>
                    <p>Os seus dados destinam-se a integrar um ficheiro de dados pessoais que a
                        Transportes do Alto Alentejo S.A poderá utilizar para a prossecução das seguintes
                        finalidades:</p>
                    <p><ol type="a">
                        <li>Recrutamento;</li>
                        <li>Agenda de contactos;</li>
                        <li>Atualização de perfil;</li>
                        <li>Envio de newsletters;</li>
                        <li>Opinião do Utente;</li>
                        <li>Sugestões e Reclamações;</li>
                        <li>Pedido de Orçamento;</li>
                        <li>Publicidade nos nossos suportes;</li>
                        <li>Compra de bilhetes/títulos de transporte;</li>
                        <li>Adesão a cartões de transporte;</li>
                        <li>Facturas Net;</li>
                        <li>Profiling;</li>
                        <li>Perdidos e Achados;</li>
                        <li>Cumprimento de outras obrigações legais ou regulamentares;</li>
                        <li>Realização de estudos de mercado, inquéritos de avaliação e análises estatísticas.</li>
                    </ol>
                    </p>
                    <p>Para a prossecução das finalidades acima identificadas a Transportes do Alto Alentejo S.A 
                        poderá proceder à interconexão dos dados recolhidos, com o propósito de atualizar
                        e completar tais dados.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Qual é o fundamento para o tratamento dos seus dados?</b></p>
                    <p>Os tratamentos associados às finalidades das alíneas a), d), e l) têm por fundamento
                        o seu consentimento, bem como uso dos dados para marketing direto que podem
                        estar associados às finalidades i) e j).</p>
                    <p>Os tratamentos associados às finalidades das alíneas b), c), e), g), h), i), j), k) e m)
                        têm por fundamento a relação contratual que se estabelece entre si e a empresa.</p>
                    <p>Os tratamentos associados às finalidades f) (Sugestões e Reclamações) e n)
                        (Cumprimento de outras obrigações legais ou regulamentares) têm por fundamento
                        o cumprimento de obrigações legais.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Com quem são partilhados os seus dados?</b></p>
                    <p>Os dados recolhidos e detidos pela Empresa Transportes do Alto Alentejo S.A
                        poderão ser transmitidos, com respeito pelo dever da confidencialidade e pelo princípio da
                        finalidade que presidiu à sua recolha, para as seguintes entidades:</p>
                    <p>
                        <ol type="a">
                            <li>Entidades do grupo em que a Transportes do Alto Alentejo S.A está inserida:</li>
                            <li>Autoridades judiciais ou administrativas, nos casos em que tal cedência seja obrigatória; </li>
                            <li>Empresas de recrutamento e seleção; </li>
                            <li>Subcontratantes que procederão ao tratamento dos dados por conta da
                                Empresa Transportes do Alto Alentejo S.A e de acordo com as finalidades
                                por esta deter.minadas</li>
                        </ol>
                    </p>
                </Row>
                <Row className="mt-5">
                    <p><b>"COOKIES"</b></p>
                    <p>"Cookies" são pequenos pedaços de informações que podem ajudar a identificar o
                        seu browser e que podem armazenar informações, por exemplo, configurações e
                        preferências do Utilizador.</p>
                    <p>A Empresa Transportes do Alto Alentejo S.A poderá armazenar cookies no seu
                        dispositivo para personalizar e facilitar ao máximo a navegação, não fornecendo
                        esses mesmos cookies referências ou dados pessoais do Utilizador.</p>
                    <p>Para mais informação deverá ler a nossa Política de Cookies.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Quais são os seus direitos? </b></p>
                    <p>Nos termos da legislação sobre Proteção de Dados Pessoais, garantimos-lhe o direito de acesso,
                        atualização, retificação, eliminação, portabilidade e apagamento dos seus dados pessoais.
                        Poderá ainda apresentar reclamações perante a Comissão Nacional da Proteção de Dados. </p>
                    <p>Conferimos-lhe, também, o direito de retirar o consentimento (não clientes) ou opor-se à
                        utilização (clientes) dos dados facultados para fins de marketing, para o envio de comunicações
                        informativas ou de inclusão em listas ou serviços informativos. Caso não o tenha efetuado aquando
                        da recolha dos dados, poderá enviar um pedido posteriormente. </p>
                    <p>O exercício destes direitos deverá ser realizado através do seguinte endereço de correio eletrónico
                        privacidade@rodalentejo.pt ou morada Estação Central de Camionagem de Évora, 2.º Piso, Avenida Túlio espanca, s/n, 7005-840 Évora. </p>
                </Row>
                <Row className="mt-5">
                    <p><b>Por quanto tempo são armazenados os seus dados?</b></p>
                    <p>O período de tempo durante o qual os dados são armazenados e conservados varia de acordo com a finalidade para a qual a informação é tratada.
                        Sempre que não exista uma exigência legal específica, os dados serão armazenados e conservados apenas pelo período
                        mínimo necessário para as finalidades que motivaram a sua recolha ou o seu posterior tratamento, findo
                        o qual os mesmos serão eliminados. </p>
                </Row>
                <Row className="mt-5">
                    <p><b>Os seus dados são tratados de forma segura?</b></p>
                    <p>A Transportes do Alto Alentejo S.A assume o compromisso de garantir a proteção da
                        segurança dos seus dados. Para o efeito, adotou diversas medidas de segurança,
                        de caráter técnico e organizativo, de forma a proteger os dados pessoais que nos
                        disponibiliza contra a sua difusão, perda, uso indevido, alteração, tratamento ou
                        acesso não autorizado, bem como contra qualquer outra forma de tratamento ilícito.
                        A Transportes do Alto Alentejo S.A exige dos seus subcontratantes e dos seus parceiros a
                        adoção de medidas de segurança equivalentes aquelas que pratica.</p>
                    <p>Não obstante as medidas de segurança praticadas pela Transportes do Alto Alentejo S.A
                        o utilizador deve adotar medidas adicionais de segurança designadamente,
                        assegurar que utiliza equipamentos e um Browser atualizados em termos de
                        segurança adequadamente configurados, com firewall ativa, antivírus e anti-spyware
                        e, certificar-se da autenticidade dos sites que visita na internet, devendo evitar
                        websites em cuja reputação não confie.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Comunicações</b></p>
                    <p>A Transportes do Alto Alentejo S.A reconhece que poderá comunicar dados dos
                        Utilizadores no âmbito de processos de fusão, aquisição e/ou incorporação em que
                        se encontre, não se considerando essa comunicação como uma transferência de
                        dados para terceiros, nem existindo qualquer tratamento subcontratado.</p>
                    <p>A Empresa Transportes do Alto Alentejo S.A poderá ainda transmitir dados a terceiros
                        no âmbito de investigações, inquéritos e processos judiciais e/ou administrativos ou
                        de natureza semelhante, desde que para tal seja devidamente ordenada por ordem
                        judicial nesse sentido.</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Transferência dos Dados </b></p>
                    <p>Caso possam ocorrer transferências de dados para países terceiros que não
                        pertençam à União Europeia ou ao Espaço Económico Europeu, a Empresa
                        Transportes do Alto Alentejo S.A cumprirá com a lei, nomeadamente no que respeita à
                        adequabilidade do país de destino no que respeita a proteção de dados pessoais e
                        aos requisitos que são aplicáveis a estas transferências, não sendo transferidos
                        dados pessoais para jurisdições que não ofereçam garantias de segurança e
                        proteção</p>
                </Row>
                <Row className="mt-5">
                    <p><b>Alterações à Política de Privacidade </b></p>
                    <p>A Transportes do Alto Alentejo S.A reserva-se o direito de, a qualquer altura, proceder a
                        reajustamentos ou alterações à presente Política de Privacidade, sendo essas
                        alterações devidamente publicitadas neste site.</p>
                </Row>
            </Container>
        );
    }
}

