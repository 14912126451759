import LocalizedStrings from 'react-localization';

export const strings = new LocalizedStrings({
    en: {
        home: "Home",
        nextDepartures: "Next Departures",
        nextDeparturesFrom: "Next departures from",
        network: "Network",
        planRoute: "Plan Route",
        schedules: "Schedules",
        schedulesByLine: "Schedules by Line",
        schedulesByStop: "Schedules by Stop",
        schedulesDisclaimer: "The displayed schedules are valid for the present day, other periods may have different schedules",
        messages: "News / Informations",
        ticketOffice: "Ticket Office",
        about: "About",
        searchPlaceholder: "Select (Stop / Line / Ticket Office)",
        originStopPlaceHolder: "Departure",
        destinationStopPlaceHolder: "Arrival",
        destination: "Destination",
        planRouteButton: "Plan Trip",
        plannedRoutes: "Trip options",
        duration: "Duration",
        includes: "Includes",
        changes: "change(s)",
        planRouteTimeFilterSelectNow: "Now",
        planRouteTimeFilterSelectStartDate: "Select departure date",
        planRouteTimeFilterSelectedStartDate: "Departure at",
        planRouteTimeFilterSelectEndDate: "Select arrival date",
        planRouteTimeFilterSelectedEndDate: "Arrival at",
        planRouteTimeDisclaimer: "Travel options more suitable for a maximum interval of up to 4 hours from the selected departure time (4 hours earlier for Arrival at option)",
        checkSchedules: "Check Schedules",
        availableLines: "Lines",
        walkAround: "Walk around",
        estimatedWaitingTime: "Estimated waiting time between",
        arrivalEstimatedAt: "Arrival estimated at",
        and: "and",
        or: "or",
        lineInfoStopsTitle: "Stops",
        lineInfoMoreScheds: "more schedules",
        showStops: "Show stops",
        showSchedule: "Show schedule",
        showTrip: "Show route",
        showMap: "Show map",
        moreSchedsTitle: "Schedules",
        noMessagesToShow: "No messages to show",
        noPassingsToShow: "No passings to show",
        noTripsToShow: "No trips to show",
        noInfoToShow: "No information to show",
        selectDate: "Select date",
        provider: "Provider",
        line: "Line",
        viewLine: "View line",
        selectPath: "Select path",
        direction: "Direction",
        directionGo: "Go",
        directionReturn: "Return",
        directionCircular: "Circular",
        daytype: "Day Type",
        locale: "en",
        hoursFilter: "Filter by hours",
        initialHourFilter: "Start time",
        finalHourFilter: "End time",
        filter: "Filter",
        close: "Close",
        done: "Done",
        copyright: "Powered by OPT",
        allRightsReserved: "All rights reserved",
        privacyPolicy: "Privacy Policy",
        notFoundTitle: "404 - Page not found",
        notFoundDescription: "The page you are trying to access does not exist.",
        developedFor: "Developed for TAA - Transportes do Alto Alentejo, S.A",
        contact: "Contact Us",
        name: "Name",
        email: "Email",
        emailWarning: "We'll never share your email with anyone else.",
        subject: "Subject",
        send: "Send",
        general: "General",
        emailSuccess: "Message sent successfully.",
        emailError: "An error occurred, please try again later.",  
        pageNotFound: "Page not found",
        delayed: "Delayed",
        aheadSchedule: "Ahead of schedule",
        complaints: "TRIMBAL Complaints",
        complaintsBook: "Complaints Book",
        phone: "Telephone",
        sendMessage: "Send us a message:",
        seeMore: "See more",
        passengerRights: "Passenger's Rights",
        ticketInfo: "Tickets and fares",
        serviceOnDemand: "Services on demand",
        moreInfoSoon: "More informations soon...",
        fares: "Fares",
        tickets: "Tickets",
        landingPageRoutePlanning: "Plan Route",
        landingPageNetwork: "Network",
        landingPageTickets: "Tickets, fares and cards",
        landingPageServiceOnDemand: "Services on demand",
        landingPageContacts: "Contacts",
        simpleTicket: "SIMPLE TICKET",
        kmSteps: "BRACKETS KMS",
        full: "FULL",
        half: "HALF",
        code: "CODE",
        driverFare: "DRIVER FARE",
        prePurchased: "PRE-PURCHASED",
        automatedLines: "AUTOMATED LINES",
        lineSubscriptions: "LINE SUBSCRIPTION",
        discount60: "60% discount",
        discount25: "25% discount",
        disclaimer: "The stop times available on the website are those in effect on the current date, subject to future changes. The stop times between towns are merely indicative, therefore you should consider the time for the town that precedes the one you're in.    ",
        contactComplaints: "Complaints",
        contactHeader: "Office / Sales",
        contactHeader2: "Sales",
        contactPortalegre: "Normal:",
        contactPortalegre1: "Monday to Friday 07h00 to 19h15",
        contactPortalegre2: "Saturdays 07h00 to 11h30 - 13h30 to 17h00",
        contactPortalegre3: "Sundays 07h00 to 11h30 - 13h30 to 18h15",
        contactPortalegreSummer: "From the 15th of July to the 15th of September:",
        contactPortalegreSummer1: "Monday to Friday 07h00 to 19h15",
        contactPortalegreSummer2: "Saturdays and Sundays 07h00 to 11h15 - 12h15 to 18h45",
        contactPonte: "Normal:",
        contactPonte1: "Monday to Thursday 06h00 to 19h15",
        contactPonte2: "Fridays 06h00 to 11h00 - 13h00 to 19h15",
        contactPonte3: "Saturdays Closed",
        contactPonte4: "Sundays 09h00 to 13h00 - 15h00 to 19h00",
        contactElvas: "Normal:",
        contactElvas1: "Mondays and Tuesdays 06h30 to 10h30 - 12h30 to 19h30",
        contactElvas2: "Thursdays to Fridays 06h30 to 19h30",
        contactElvas3: "Saturdays and Sundays 06h30 to 11h15 - 12h45 to 18h45",
        contactEveryday: "Everyday 06h00 to 21h00",
        contactExceptSatturday: "Everyday except Saturdays 06h30 to 20h30",
        contactWorkingDays: "Working days 06h30 to 20h30",
        contactSaturdays: "Saturdays 06h30 to 10h30 / 13h30 to 20h30",
        contactSundays: "Sundays 07h45 to 10h45 / 13h45 to 20h30",
        contactsPrivacyDisclaimer: "The data collected is intended to respond to your request and facilitate contacts between you and Rodoviária do Alentejo, S.A.. Find out more about our ",
        contactsPrivacyCheckbox: "I authorize my data to be used to carry out promotional and direct marketing actions, carried out through automatic calling devices, fax devices, by email, SMS, MMS, or other means that allow the reception of messages regardless of the intervention of the recipients.",
        notes: "View Notes",
        simpleOnBoard: "Simple on Board Ticket",
        fullAndHalf: "Full and Half",
        fullAndHalfConditions: "Details:",
        fullAndHalfConditionA: "Non-personalized;",
        fullAndHalfConditionB: "Sold on board the bus;",
        fullAndHalfConditionC: "Price according to the table.",
        monthly: "Monthly Pass",
        monthlyConditions: "Must be validated on board the bus.",
        monthlyRequirements: "Necessary documents:",
        monthlyRequirementA: "Citizen Card;",
        monthlyRequirementB: "1 photograph.",
        passCIM: "CIM Social Pass",
        passCIMConditions: "Details:",
        passCIMConditionA: "Personalized, personal and non-transferable title;",
        passCIMConditionB: "Sold at the ticket office;",
        passCIMConditionC: "Renewed monthly at the ticket office or on board;",
        passCIMConditionD: "Valid for the month and Origin/Destination for which it was purchased for, with an unlimited number of trips;",
        passCIMConditionE: "Must be validated on board the bus;",
        passCIMConditionF: "Discounts up to the 28th bracket.",
        passCIMRequirements: "Necessary documents:",
        passCIMRequirementA: "Citizen Card;",
        passCIMRequirementB: "1 photograph.",
        passes418s23: "4_18 and Sub23 Passes",
        pass418: "Passe 4_18",
        pass418Users: "O passe \"4_18\" destina-se aos estudantes do ensino não superior, dos 4 aos 18 anos, inclusive, que não beneficiem de transporte escolar.",
        pass418Conditions: "Details:",
        pass418ConditionA: "Personalized, personal and non-transferable title;",
        pass418ConditionB: "Sold at the ticket office;",
        pass418ConditionC: "Renewed monthly at the ticket office or on board;",
        pass418ConditionD: "Valid for the month and Origin/Destination for which it was purchased for, with an unlimited number of trips;",
        pass418ConditionE: "Must be validated on board the bus;",
        pass418ConditionF: "It is intended for all children and young people, from 4 to 18 years old (inclusive) who do not attend higher education and who meet the requirements in force.",
        pass418Requirements: "Necessary documents:",
        pass418RequirementA: "Citizen Card;",
        pass418RequirementB: "1 Photofraph;",
        pass418RequirementC: "Obtain a Declaration of Enrollment 4 18 from the educational establishment.",
        pass418Renewal: "Must be renewed annually.",
        passS23: "Sub23 passes (public or private higher education students, up to 23 years old)",
        passS23ShortName: "Sub23 Pass",
        passS23Conditions: "Details:",
        passS23ConditionA: "Personalized, personal and non-transferable title;",
        passS23ConditionB: "Sold at the ticket office;",
        passS23ConditionC: "Renewed monthly at the ticket office or on board;",
        passS23ConditionD: "Valid for the month and Origin/Destination for which it was purchased for, with an unlimited number of trips;",
        passS23ConditionE: "Must be validated on board the bus;",
        passS23ConditionF: "Intended for higher education students, public or private, aged up to 23 years (inclusive) who meet the requirements in force.",
        passS23Requirements: "Necessary documents:",
        passS23RequirementA: "Citizen Card;",
        passS23RequirementB: "1 Photofraph;",
        passS23RequirementC: "Obtain a Declaration of Enrollment SUB 23 from the educational establishment.",
        passS23Renewal: "Must be renewed annually.",
        passes418s23MoreInfo: "For more information, access the following link:",
        passAntigoCombatente: "Antigo Combatente (Former Combatant) Pass",
        passAntigoCombatenteDescription: "Antigo Combatente pass is a tariff modality that grants an exemption from the payment of the monthly ticket.",
        passAntigoCombatenteUsersAndConditions: "It is intended for all former combatants, holders of the former combatant card, as well as for the widow and widower of the former combatant. To gain access to the Antigo Combatente Pass, the potential beneficiary must fill and submit the respective application for membership, accompanied by the following documents:",
        passAntigoCombatenteConditionA: "Presentation of a former combatant's card or a former combatant's widow and widower's card;",
        passAntigoCombatenteConditionB: "Presentation of ID card or other equivalent valid title;",
        passAntigoCombatenteConditionC: "Residence certificate issued by AT;",
        passAntigoCombatenteConditionD: "1 Photograph.",
        passAntigoCombatenteMoreInfo: "For more information, access the following link:",
        passAntigoCombatenteLink: "Antigo Combatente Pass (imt-ip.pt)",
        ticketsDownload: "Download Full Ticket Information",
    },
    pt: {
        home: "Início",
        nextDepartures: "Próximas Partidas",
        nextDeparturesFrom: "Próximas partidas de",
        network: "Rede",
        planRoute: "Planear Rota",
        schedules: "Horários",
        schedulesByLine: "Horários por Linha",
        schedulesByStop: "Horários por Paragem",
        schedulesDisclaimer: "Os horários apresentados são válidos para o dia atual, podendo não ter correspondência para outros períodos",
        messages: "Notícias / Informações",
        ticketOffice: "Posto de Venda",
        about: "Acerca de",
        searchPlaceholder: "Pesquisar (Paragem / Linha / Posto de Venda)",
        originStopPlaceHolder: "Partida",
        destinationStopPlaceHolder: "Chegada",
        destination: "Destino",
        planRouteButton: "Planear Viagem",
        plannedRoutes: "Opções de viagem",
        duration: "Duração",
        includes: "Inclui",
        changes: "transbordo(s)",
        planRouteTimeFilterSelectNow: "Agora",
        planRouteTimeFilterSelectStartDate: "Escolher data de partida",
        planRouteTimeFilterSelectedStartDate: "Partida às",
        planRouteTimeFilterSelectEndDate: "Escolher data de chegada",
        planRouteTimeFilterSelectedEndDate: "Chegada às",
        planRouteTimeDisclaimer: "Opções de viagem mais adequadas para um intervalo máximo de até 4 horas a partir da hora indicada(4 horas antes para opção de Chegada às)",
        checkSchedules: "Consultar Horários",
        availableLines: "Linhas",
        walkAround: "Ir a pé cerca de",
        estimatedWaitingTime: "Tempo de espera estimado entre as",
        arrivalEstimatedAt: "Chegada estimada às",
        and: "e",
        or: "ou",
        lineInfoStopsTitle: "Paragens",
        lineInfoMoreScheds: "mais horários",
        showStops: "Ver paragens",
        showSchedule: "Ver horário",
        showTrip: "Ver viagem",
        showMap: "Ver mapa",
        moreSchedsTitle: "Horários",
        noMessagesToShow: "Sem mensagens",
        noPassingsToShow: "Sem tempos de passagem",
        noTripsToShow: "Sem viagens para mostrar",
        noInfoToShow: "Sem informação para mostrar",
        selectDate: "Selecione uma data",
        provider: "Operador",
        line: "Linha",
        viewLine: "Ver linha",
        selectPath: "Escolha um percurso",
        direction: "Sentido",
        directionGo: "Ida",
        directionReturn: "Volta",
        directionCircular: "Circular",
        daytype: "Tipo de Dia",
        locale: "pt",
        hoursFilter: "Filtrar por horas",
        initialHourFilter: "Hora inicial",
        finalHourFilter: "Hora final",
        filter: "Filtrar",
        close: "Fechar",
        done: "Concluído",
        copyright: "Powered by OPT",
        allRightsReserved: "Todos os direitos reservados",        
        privacyPolicy: "Política de Privacidade",
        notFoundTitle: "404 - Página não encontrada",
        notFoundDescription: "A página à qual está a tentar aceder não existe.",
        developedFor: "Desenvolvido para TAA - Transportes do Alto Alentejo, S.A",
        contact: "Contacto",
        name: "Nome",
        email: "Email",
        emailWarning: "O seu endereço nunca será partilhado com mais nenhuma organização.",
        subject: "Assunto",
        send: "Enviar",
        general: "Geral",
        emailSuccess: "Mensagem enviada com sucesso.",
        emailError: "Ocorreu um erro, por favor tente mais tarde.",  
        pageNotFound: "Página não encontrada",
        delayed: "Atrasado",
        aheadSchedule: "Adiantado",
        complaints: "Reclamações TRIMBAL",
        complaintsBook: "Livro de Reclamações",
        phone: "Telefone",
        sendMessage: "Envie-nos uma mensagem:",
        seeMore: "Ver mais",
        passengerRights: "Direitos dos Passageiros",
        ticketInfo: "Títulos e tarifas",
        serviceOnDemand: "Serviços a pedido",
        moreInfoSoon: "Mais informações brevemente...",
        fares: "Tarifário",
        tickets: "Títulos",
        landingPageRoutePlanning: "Planear rota",
        landingPageNetwork: "Rede",
        landingPageTickets: "Títulos, tarifas e cartões",
        landingPageServiceOnDemand: "Serviços a pedido",
        landingPageContacts: "Contactos",
        simpleTicket: "BILHETE SIMPLES",
        kmSteps: "ESCALÕES KMS",
        full: "INTEIRO",
        half: "MEIO",
        code: "CÓD.",
        driverFare: "TARIFA MOTORISTA",
        prePurchased: "PRÉ-COMPRADOS",
        automatedLines: "CARREIRAS AUTOMATIZADAS",
        lineSubscriptions: "ASSINATURA LINHA",
        discount60: "60% desconto",
        discount25: "25% desconto",
        disclaimer: "Os horários disponibilizados no site são os que estão em vigor na data atual, estando sujeitos a alterações futuras. Os horários das paragens entre localidades são meramente indicativos pelo que deverá considerar o horário da localidade que antecede o local onde se encontra.    ",
        contactComplaints: "Reclamações",
        contactHeader: "Instalações / Posto de Venda",
        contactHeader2: "Posto de Venda",
        contactPortalegre: "Normal:",
        contactPortalegre1: "Segunda a Sexta 07h00 às 19h15",
        contactPortalegre2: "Sábados 07h00 às 11h30 - 13h30 às 17h00",
        contactPortalegre3: "Domingos 07h00 às 11h30 - 13h30 às 18h15",
        contactPortalegreSummer: "De 15 de Julho até 15 de Setembro:",
        contactPortalegreSummer1: "Segunda a Sexta 07h00 às 19h15",
        contactPortalegreSummer2: "Sábados e Domingos 07h00 às 11h15 - 12h15 às 18h45",
        contactPonte: "Normal:",
        contactPonte1: "Segunda a Quinta 06h00 às 19h15",
        contactPonte2: "Sextas 06h00 às 11h00 - 13h00 às 19h15",
        contactPonte3: "Sábados Fechado",
        contactPonte4: "Domingos 09h00 às 13h00 - 15h00 às 19h00",
        contactElvas: "Normal:",
        contactElvas1: "Segunda e Terça 06h30 às 10h30 - 12h30 às 19h30",
        contactElvas2: "Quarta a Sexta 06h30 às 19h30",
        contactElvas3: "Sábado e Domingo 06h30 às 11h15 - 12h45 às 18h45",
        contactEveryday: "Todos os dias 06h00 às 21h00",
        contactExceptSatturday: "Dias úteis, Domingos e feriados 06h30 às 20h30",
        contactWorkingDays: "Dias úteis 06h30 às 20h30",
        contactSaturdays: "Sábados 06h30 às 10h30 / 13h30 às 20h30",
        contactSundays: "Domingo 07h45 às 10h45 / 13h45 às 20h30",
        contactsPrivacyDisclaimer: "Os dados recolhidos destinam-se a dar resposta ao seu pedido e a facilitar os contactos entre si e a Rodoviária do Alentejo, S.A.. Saiba mais sobre a nossa ",
        contactsPrivacyCheckbox: "Autorizo que os meus dados sejam usados para a realização de ações de promoção e de marketing direto, levadas a cabo através de aparelhos de chamada automática, aparelhos de telecópia, por correio eletrónico, SMS, MMS, ou outros meios que permitam a receção de mensagens independentemente da intervenção dos destinatários.",
        notes: "Ver Notas",
        simpleOnBoard: "Bilhete Simples a Bordo",
        fullAndHalf: "Inteiro e Meio",
        fullAndHalfConditions: "Detalhes:",
        fullAndHalfConditionA: "Título não personalizado;",
        fullAndHalfConditionB: "Vendido a bordo;",
        fullAndHalfConditionC: "Valor de acordo com a tabela tarifária.",
        monthly: "Passe Mensal (Assinatura de Linha)",
        monthlyConditions: "Validado a bordo do autocarro.",
        monthlyRequirements: "Documentos necessários:",
        monthlyRequirementA: "Cartão de Cidadão;",
        monthlyRequirementB: "1 fotografia.",
        passCIM: "Passe Social CIM",
        passCIMConditions: "Detalhes:",
        passCIMConditionA: "Título personalizado, pessoal e intransmissível;",
        passCIMConditionB: "Vendido na bilheteira;",
        passCIMConditionC: "Recarregado mensalmente na bilheteira ou a bordo dos autocarros;",
        passCIMConditionD: "Válido para o mês e Origem/Destino para o qual foi adquirido com número de viagens ilimitadas;",
        passCIMConditionE: "Tem de ser validado a bordo do autocarro;",
        passCIMConditionF: "Desconto até ao escalão 28.",
        passCIMRequirements: "Documentos necessários:",
        passCIMRequirementA: "Cartão de Cidadão;",
        passCIMRequirementB: "1 fotografia.",
        passes418s23: "Passes 4_18 e Sub23",
        pass418: "Passe 4_18",
        pass418Users: "O passe \"4_18\" destina-se aos estudantes do ensino não superior, dos 4 aos 18 anos, inclusive, que não beneficiem de transporte escolar.",
        pass418Conditions: "Detalhes:",
        pass418ConditionA: "Título personalizado, pessoal e intransmissível;",
        pass418ConditionB: "Vendido na Bilheteira;",
        pass418ConditionC: "Recarregado mensalmente na bilheteira ou a bordo dos autocarros;",
        pass418ConditionD: "Válido para o mês e Origem / Destino para o qual foi adquirido com número de viagens ilimitadas;",
        pass418ConditionE: "Tem de ser validado a bordo do autocarro;",
        pass418ConditionF: "Destina-se a todas as crianças e jovens, dos 4 aos 18 anos (inclusive) que não frequentem o ensino superior que cumpram os requisitos em vigor.",
        pass418Requirements: "Documentos necessários:",
        pass418RequirementA: "Cartão de Cidadão;",
        pass418RequirementB: "1 Fotografia;",
        pass418RequirementC: "Obter junto do estabelecimento de ensino uma Declaração de Matrícula 4 18.",
        pass418Renewal: "Tem de ser renovado todos os anos.",
        passS23: "Passes Sub23 (estudantes do ensino superior público ou privado, até aos 23 anos)",
        passS23ShortName: "Passe Sub23",
        passS23Conditions: "Detalhes:",
        passS23ConditionA: "Título personalizado, pessoal e intransmissível;",
        passS23ConditionB: "Vendido na Bilheteira;",
        passS23ConditionC: "Recarregado mensalmente na bilheteira ou a bordo dos autocarros;",
        passS23ConditionD: "Válido para o mês e Origem / Destino para o qual foi adquirido com número de viagens ilimitadas;",
        passS23ConditionE: "Tem de ser validado a bordo do autocarro;",
        passS23ConditionF: "Destina-se a estudantes do ensino superior, público ou privado, com idade até aos 23 anos (inclusive) que cumpram os requisitos em vigor.",
        passS23Requirements: "Documentos necessários:",
        passS23RequirementA: "Cartão de Cidadão;",
        passS23RequirementB: "1 Fotografia;",
        passS23RequirementC: "Obter junto do estabelecimento de ensino uma Declaração de Matrícula SUB 23.",
        passS23Renewal: "Tem de ser renovado todos os anos.",
        passes418s23MoreInfo: "Para mais informações aceda ao seguinte link:",
        passAntigoCombatente: "Passe Antigo Combatente",
        passAntigoCombatenteDescription: "O Passe de Antigo Combatente é uma modalidade tarifária que confere uma isenção do pagamento do título mensal.",
        passAntigoCombatenteUsersAndConditions: "Destina-se a todos os antigos combatentes, detentores do cartão de antigo combatente, bem como para a viúva e viúvo do antigo combatente. Para ter acesso ao Passe de Antigo Combatente, o potencial beneficiário deverá preencher e apresentar o respetivo requerimento de adesão, acompanhado dos seguintes documentos:",
        passAntigoCombatenteConditionA: "Apresentação de cartão de antigo combatente ou de cartão de viúva e viúvo de antigo combatente;",
        passAntigoCombatenteConditionB: "Apresentação do cartão de cidadão ou outro título válido equivalente;",
        passAntigoCombatenteConditionC: "Certidão de domicílio fiscal emitida pela AT;",
        passAntigoCombatenteConditionD: "1 Fotografia.",
        passAntigoCombatenteMoreInfo: "Para mais informações aceda ao seguinte link:",
        passAntigoCombatenteLink: "Passe de Antigo Combatente (imt-ip.pt)",
        ticketsDownload: "Descarregar Informação Tarifária Completa",
    }
});

export const changeLanguage = (languageKey) => {
    strings.setLanguage(languageKey);
}
