import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { strings } from '../resources/strings';
import { ContactCommand } from './commands/ContactCommand';

export class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commands: {
                contacts: new ContactCommand()
            },
            sending: false,
            result: undefined
        };
    }

    sendMail = (e) => {
        e.preventDefault();

        this.setState({ sending: true });
        this.state.commands.contacts.sendEmail({
            name: e.target.name.value,
            email: e.target.email.value,
            subject: `${e.target.subjectType.value} - ${e.target.subject.value}`,
            message: e.target.message.value
        }, (r) => this.sendSuccessCallback(r), (r) => this.sendErrorCallback(r));
    }

    sendSuccessCallback(result) {
        this.setState({
            sending: false,
            result: true
        });
    }

    sendErrorCallback(result) {
        this.setState({
            sending: false,
            result: false
        });
    }

    onCheckboxChange() {
        const { sending } = this.state;
        this.setState({
            sending: !sending,
        });
    }

    render() {
        const subjectOptions = [strings.general, strings.landingPageTickets, strings.contactComplaints];

        return (
            <Container fluid className="mb-5" >
                <Row>
                    <Col lg={4}>
                        <div class="mt-5">
                            <b>{strings.contactHeader}</b><br />
                            <b>Portalegre<br />
                                Central de Camionagem / Av. Do Brasil, S/N<br />
                                7300 - 068 Portalegre</b><br />
                            <b>{strings.email}:</b> geral@transportesaltoalentejo.pt<br />
                            <b>{strings.phone}:</b> 245 033 332<br /><br />

                            <p>{strings.contactPortalegre}
                                <ul>
                                    <li>{strings.contactPortalegre1}</li>
                                    <li>{strings.contactPortalegre2}</li>
                                    <li>{strings.contactPortalegre3}</li>
                                </ul>
                            </p>

                            <p>{strings.contactPortalegreSummer}
                                <ul>
                                    <li>{strings.contactPortalegreSummer1}</li>
                                    <li>{strings.contactPortalegreSummer2}</li>
                                </ul>
                            </p>
                        </div>
                        <Form onSubmit={this.sendMail} className="mb-3">
                            <div class="mt-3">
                                {strings.sendMessage}
                            </div>

                            <Form.Group className="mb-3" controlId="name">
                                <Form.Control className="contact-input" required placeholder={strings.name} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="email">
                                <Form.Control type="email" className="contact-input" required placeholder={strings.email} />
                                <Form.Text className="text-muted">
                                    {strings.emailWarning}
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="subjectType">
                                <Form.Select className="contact-input" aria-label={strings.subject}>
                                    {
                                        subjectOptions.map((option, index) =>
                                            <option key={option} value={option}>{option}</option>
                                        )
                                    }

                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="subject">
                                <Form.Control className="contact-input" required placeholder={strings.subject} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="message">
                                <Form.Control as="textarea" rows={5} className="contact-input" required placeholder={strings.message} />
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={!this.state.sending}>
                                {strings.send}
                            </Button>

                            <Form.Group>
                                <br />
                                {strings.contactsPrivacyDisclaimer}<a href="docs/politica_privacidade.pdf">{strings.privacyPolicy}</a>.
                                <br />
                                <br />
                                <Form.Check
                                    label={strings.contactsPrivacyCheckbox}
                                    checked={this.state.sending}
                                    onChange={() => this.onCheckboxChange()}
                                />
                                <br />
                            </Form.Group>

                            {this.state.result !== undefined
                                ? <Alert className="mt-3" variant={this.state.result ? "success" : "danger"}>
                                    {this.state.result
                                        ? strings.emailSuccess
                                        : strings.emailError}
                                </Alert>
                                : null}
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }
}
