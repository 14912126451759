import React, { Component } from 'react';
import { useMap, MapContainer, TileLayer, Pane, Polyline, Marker, Tooltip } from 'react-leaflet';
import { routeOriginIcon, routeDestinationIcon, lineInfoStopIcon } from '../leaflet/StopIcons';
import { strings } from '../../resources/strings';
import 'leaflet/dist/leaflet.css';

export class DetailedRouteMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mapComponent: null
        }
    }

    //HELPER FUNCTIONS
    getBounds(trip) {
        if (undefined === trip) {
            return [{ lat: 41.1483096, lng: -8.6108148 }, { lat: 41.1442942, lng: -8.6105935 }];
        }

        return [{ lat: trip.maxLatitude, lng: trip.maxLongitude }, { lat: trip.minLatitude, lng: trip.minLongitude }];
    }

    panToSpecificSubtrip(subtrip) {
        this.state.mapComponent.flyToBounds([{ lat: subtrip.maxLatitude, lng: subtrip.maxLongitude }, { lat: subtrip.minLatitude, lng: subtrip.minLongitude }]);
    }

    //------------------------

    renderPolyLine(subTrip, index) {
        if (Array.isArray(subTrip.breakpoints) && 0 < subTrip.breakpoints.length) {
            return (
                <Polyline key={`polyline-${index}`}
                    positions={subTrip.breakpoints.map(breakpoint => [breakpoint.latitude, breakpoint.longitude])}
                    color="#316094"
                    dashArray={subTrip.isWalking ? "2 8" : null} />
            );
        }

        return (
            <Polyline key={`polyline-${index}`}
                positions={subTrip.passings.map(passing => [passing.x, passing.y])}
                color="#316094"
                dashArray={subTrip.isWalking ? "2 8" : null} />
        );
    }

    renderPath(trip) {
        if (undefined === trip) {
            return null;
        }

        return (
            <div key="polyLine-line-info">
                {
                    trip.subTrips.map((subTrip, index) => this.renderPolyLine(subTrip, index))
                }
            </div>
        );
    }

    renderPathStops(trip) {
        if (undefined === trip) {
            return null;
        }

        return (
            <div>
                <Marker
                    key={`marker-origin`}
                    position={[trip.startPlace.coordX, trip.startPlace.coordY]}
                    icon={routeOriginIcon}>
                    {this.renderTooltip(trip, "origin")}
                </Marker>

                {this.renderSubtripMarkers(trip.subTrips)}


                <Marker
                    key={`marker-destination`}
                    position={[trip.endPlace.coordX, trip.endPlace.coordY]}
                    icon={routeDestinationIcon}>
                    {this.renderTooltip(trip.endPlace, "destination")}
                </Marker>
            </div>
        );
    }

    renderSubtripMarkers(subtrips) {
        return subtrips.map((subtrip, index) => {
            if (0 !== subtrip.passings.length && 0 !== index) {
                return (
                    <Marker
                        key={`marker-subtrip-${index}`}
                        position={[subtrip.passings[0].x, subtrip.passings[0].y]}
                        icon={lineInfoStopIcon}>
                        {this.renderTooltip(subtrip, "subtrip")}
                    </Marker>
                );
            }

            return null;
        });
    }

    renderTooltip(data, type) {
        if ("origin" === type) {
            return (
                <Tooltip key={`tooltip-origin`}>
                    <div>
                        <b>{data.startPlace.name}</b>
                        <br />
                        {
                            0 !== data.subTrips.length ?
                                data.subTrips[0].isWalking ?
                                    <span>{strings.walkAround} {data.subTrips[0].walkingTime}, {data.subTrips[0].distance}m</span>
                                    :
                                    <span>{data.subTrips[0].lineCode} {data.subTrips[0].passings[0].destination}</span>
                                :
                                null
                        }
                    </div>
                </Tooltip>
            );
        }

        if ("destination" === type) {
            return (
                <Tooltip key={`tooltip-destination`}>
                    <span><b>{data.name}</b></span>
                </Tooltip>
            );
        }

        if (0 !== data.passings.length) {
            return (
                <Tooltip key={`tooltip-subtrip-${data.order}`}>
                    <div>
                        <b>{data.passings[0].name}</b>
                        <br />
                        {
                            data.isWalking ?
                                <span>{strings.walkAround} {data.walkingTime}, {data.distance}m</span>
                                :
                                <span>{data.lineCode} {data.passings[0].destination}</span>
                        }
                    </div>
                </Tooltip>
            );
        }

        return null;
    }

    render() {
        const { trip } = this.props;
        return (
            <MapContainer className="map-container" bounds={this.getBounds(trip)} scrollWheelZoom={true} whenCreated={(e) => this.setState({ mapComponent: e })}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
                <SetBounds bounds={this.getBounds(trip)} />
                <Pane>
                    {this.renderPath(trip)}
                </Pane>
                <Pane>
                    {this.renderPathStops(trip)}
                </Pane>
            </MapContainer>
        );
    }
}

function SetBounds({ bounds }) {
    const map = useMap();
    map.fitBounds(bounds);
    return null;
}
