import React, { Component } from 'react';
import { MenuItem } from './MenuItem';
import './NavMenu.css';
import { strings } from "../../resources/strings.js";
import { PropTypes } from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    static contextTypes = {
        updateLanguage: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    changeLanguage(languageKey) {
        const { updateLanguage } = this.context;
        updateLanguage(languageKey);
    }

    render() {
        const { isMenuOpen } = this.props;

        return (
            <nav className={isMenuOpen ? "icon-nav-open" : "icon-nav"}>
                <div className="menu-background-header" />
                <div className="menu-background" />
                <div className="icon-nav-item">
                    <div className="menu-toggle">
                        <div className="d-flex">
                            <div className={"icon-menu-1"} onClick={this.props.toggleMenuHandler} />
                            <div className="margin-left-22 margin-top-neg-46">
                                <LinkContainer to="/home">
                                    <img width={isMenuOpen ? "150px" : "0px"} src="icons/taa.png" alt="TAA" />
                                </LinkContainer>
                            </div>
                        </div>
                    </div>

                    <MenuItem key="menu-home" label={strings.home} icon="icon-home" to="/home" />
                    <MenuItem key="menu-network" label={strings.network} icon="icon-zones" to="/network" />
                    <MenuItem key="menu-plan-route" label={strings.planRoute} icon="icon-routeplan" to="/plan-route" />
                    <MenuItem key="menu-schedules-by-stop" label={strings.schedulesByStop} icon="icon-clock" to="/schedulesbystop" />
                    <MenuItem key="menu-schedules" label={strings.schedulesByLine} icon="icon-schedules-by-line" to="/schedules" />
                    <MenuItem key="menu-ticketinfo" label={strings.ticketInfo} icon="icon-ticket" to="/ticket-info" />
                    <MenuItem key="menu-ticketOffice" label={strings.ticketOffice} icon="icon-ticket-office" to="/ticket-office" />
                    {/*<MenuItem key="menu-on-demand" label={strings.serviceOnDemand} icon="icon-bus" to="/service-on-demand" />*/}
                    <MenuItem key="menu-contact" label={strings.contact} icon="icon-messages" to="/contacts" />
                    <MenuItem key="menu-warnings" label={strings.messages} icon="icon-warning" to="/news" />
                    {/*<MenuItem key="menu-about" label={strings.about} icon="icon-info" to="/about" />*/}
                    <div className={"icon-menu-1 menu-flag-icons language-panel"}>
                        <img width={isMenuOpen ? "25px" : "0px"} src="icons/pt-flag.png" alt="pt" onClick={() => this.changeLanguage('pt')} />
                        <img className={"icon-uk-flag"} width={isMenuOpen ? "25px" : "0px"} src="icons/uk-flag.png" alt="pt" onClick={() => this.changeLanguage('en')} />
                    </div>
                </div>
            </nav>
        );
    }
}
