import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MapContainer, Marker, TileLayer, Tooltip } from 'react-leaflet';
import { strings } from '../../resources/strings';
import { ticketOfficeIcon } from '../leaflet/StopIcons';

export class TicketOffice extends Component {
    render() {
        return (
            <Container fluid className="mb-5" >
                <Row>
                    <Col lg={4}>
                        <div class="mt-5">
                            <b>{strings.contactHeader}</b><br />
                            <b>Portalegre<br />
                                Central de Camionagem / Av. Do Brasil, S/N<br />
                                7300 - 068 Portalegre</b><br />
                            <b>{strings.email}:</b> geral@transportesaltoalentejo.pt<br />
                            <b>{strings.phone}:</b> 245 033 332<br /><br />

                            <p>{strings.contactPortalegre}
                                <ul>
                                    <li>{strings.contactPortalegre1}</li>
                                    <li>{strings.contactPortalegre2}</li>
                                    <li>{strings.contactPortalegre3}</li>
                                </ul>
                            </p>

                            <p>{strings.contactPortalegreSummer}
                                <ul>
                                    <li>{strings.contactPortalegreSummer1}</li>
                                    <li>{strings.contactPortalegreSummer2}</li>
                                </ul>
                            </p>
                        </div>

                        <div className="map-container-formatter">
                            <MapContainer className="map-container-ticket-office" center={[39.29763, -7.43259]} zoom={16} scrollWheelZoom={true}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                                />
                                <Marker
                                    key={`to-rodoviaria-do-alentejo`}
                                    position={[39.29763, -7.43259]}
                                    icon={ticketOfficeIcon}>

                                    <Tooltip>
                                        <strong>Portalegre</strong>
                                    </Tooltip>
                                </Marker>
                            </MapContainer>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div class="mt-5">
                            <b>{strings.contactHeader2}</b><br />
                            <b>Ponte de Sôr<br />
                                Av. da Liberdade, nº 26 - C<br />
                                7400 - 216 Ponte de Sôr</b><br />
                            <b>{strings.phone}:</b> 242 206 630<br /><br />
                            {/*
                            <div class="map-spacing-formatter">
                                <br />
                            </div>
                            <br />
                            */}
                            <p>{strings.contactPonte}
                                <ul>
                                    <li>{strings.contactPonte1}</li>
                                    <li>{strings.contactPonte2}</li>
                                    <li>{strings.contactPonte3}</li>
                                    <li>{strings.contactPonte4}</li>
                                </ul>
                            </p>
                            {/*
                            <div class="map-spacing-formatter-2">
                                <br />
                                <br />
                                <br />
                            </div>
                            */}
                        </div>

                        <div className="map-container-formatter">
                            <MapContainer className="map-container-ticket-office" center={[39.24866, -8.00858]} zoom={16} scrollWheelZoom={true}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                                />
                                <Marker
                                    key={`to-estacao-de-ponte-de-sor`}
                                    position={[39.24866, -8.00858]}
                                    icon={ticketOfficeIcon}>

                                    <Tooltip>
                                        <strong>Ponte de Sôr</strong>
                                    </Tooltip>
                                </Marker>
                            </MapContainer>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div class="mt-5">
                            <b>{strings.contactHeader2}</b><br />
                            <b>Elvas<br />
                                Rua Principal, Edifício Novo, Terminal Rodoviário<br />
                               7350 - 028 Elvas</b><br />
                            <b>{strings.phone}:</b> 268 622 875<br /><br />
                            {/*
                            <div class="map-spacing-formatter">
                                <br />
                            </div>
                            <br />
                            */}
                            <p>{strings.contactElvas}
                                <ul>
                                    <li>{strings.contactElvas1}</li>
                                    <li>{strings.contactElvas2}</li>
                                    <li>{strings.contactElvas3}</li>
                                </ul>
                            </p>
                            {/*
                            <div class="map-spacing-formatter-2">
                                <br />
                                <br />
                                <br />
                            </div>
                            */}
                        </div>

                        <div className="map-container-formatter">
                            <MapContainer className="map-container-ticket-office" center={[38.87491, -7.16038]} zoom={16} scrollWheelZoom={true}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                                />
                                <Marker
                                    key={`to-estacao-de-elvas`}
                                    position={[38.87491, -7.16038]}
                                    icon={ticketOfficeIcon}>

                                    <Tooltip>
                                        <strong>Elvas</strong>
                                    </Tooltip>
                                </Marker>
                            </MapContainer>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
